import React from 'react';
import {ERRORPAYEMENT, PRINTER, VALIDATEIMAGE, ERROR} from '../../../assets';
import {usePayemetValide} from './usePayementValide';

const PayementValidate: React.FC = () => {
    const {reponsePayement, checkUuidTransaction} = usePayemetValide()
    // console.log(checkUuidTransaction);
    return (
        <div className="paiement-valide">
            <div className='paiement-image mb-4'>
                {reponsePayement?.Status === "FAILED" ? <img src={ERROR} className='my-3 w-75 rounded'/> :
                    <img src={VALIDATEIMAGE} className='w-25 rounded'/>
                }
            </div>
            <div className="title-paiment-valid">
                {reponsePayement?.Status === "FAILED" ? "Echec de Paiement... " : "Paiement Validé "}
            </div>
            {reponsePayement?.Status === "FAILED" &&
                <div className='mt-2'>
                    <button type="button" className="btn-copay text-white" onClick={() => {
                        history.back();
                    }}>
                        {/*<img src={PRINTER}/> */}
                        Retour sur le site site du marchand
                    </button>
                </div>
            }
            {reponsePayement?.Status !== "FAILED" &&
                <div>
                    <div className='card mt-2 card-paiement-infos'>
                        <div className="card-body">
                            <div className="row align-items-start">
                                <div className="col">
                                    <span className='title-card-paiement'>Vendeur :</span> <span
                                    className="little-card-paiement-recu"> {checkUuidTransaction?.plateforme.nom}</span>
                                </div>
                            </div>
                            <div className="row align-items-start mt-1">
                                <div className="col">
                                    <span className='title-card-paiement'>Date de Paiement : </span> <span
                                    className="little-card-paiement-recu"> {new Date().getDate()} - {String(new Date().getMonth()+1).padStart(2, '0')} - {new Date().getFullYear()} à {new Date().getHours()}h - {new Date().getMinutes()}min - {new Date().getSeconds()}s</span>
                                </div>
                                {/*<div className="col">*/}
                                {/*    <span className='title-card-paiement'>ID Opérateur :</span> <span className="little-card-paiement-recu"> h55467333</span>*/}
                                {/*</div>*/}
                            </div>
                            <div className="row align-items-start mt-1">
                                <div className="col">
                                    <span className='title-card-paiement'>ID Transaction :</span> <span
                                    className="little-card-paiement-recu"> {checkUuidTransaction?.transaction.uuid}</span>
                                </div>
                            </div>
                            <div className="row align-items-start mt-1">
                                <div className="col">
                                    <span className='title-card-paiement'>ID Transaction Externe:</span> <span
                                    className="little-card-paiement-recu"> {checkUuidTransaction?.transaction.idTransactionExt}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mt-2'>
                        <button type="button" className="btn-copay text-white" onClick={() => {
                            const redirectUrl = checkUuidTransaction?.transaction.redirectUrl || "";
                            window.location.href = redirectUrl;
                        }}>
                            {/*<img src={PRINTER}/> */}
                            Retour sur le site site du marchand
                        </button>
                    </div>
                </div>
            }
        </div>
    );
};

export default PayementValidate;