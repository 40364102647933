export class Partenaire {
  private _uuid: string;
  private _pays: string;
  private _ville: string;
  private _siteWeb: string;
  private _raison: string;

  constructor(
    uuid: string,
    pays: string,
    ville: string,
    siteWeb: string,
    raison: string
  ) {
    this._uuid = uuid;
    this._pays = pays;
    this._siteWeb = siteWeb;
    this._ville = ville;
    this._raison = raison;
  }

  static deserialize(data: any): Partenaire {
    return (
      data &&
      new Partenaire(
        data.uuid,
        data.pays,
        data.ville,
        data.siteWeb,
        data.raison
      )
    );
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get pays(): string {
    return this._pays;
  }

  set pays(value: string) {
    this._pays = value;
  }

  get ville(): string {
    return this._ville;
  }

  set ville(value: string) {
    this._ville = value;
  }

  get siteWeb(): string {
    return this._siteWeb;
  }

  set siteWeb(value: string) {
    this._siteWeb = value;
  }

  get raison(): string {
    return this._raison;
  }

  set raison(value: string) {
    this.raison = value;
  }
}
