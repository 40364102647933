import axios, { AxiosResponse } from 'axios';



export const forThisUrl = (apiURL: string) => {

  return {
    getAxiosInstance: () => {
      const client = axios.create({
        baseURL: apiURL
      });
      return client;
    },
  };
};



