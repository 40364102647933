export class Transaction {
    private _uuid: string;
    private _paiementDate: string;
    private _idTransactionExt: string;
    private _montantPaye: number;
    private _devise: string;
    private _telephone: string;
    private _typeTransaction: string;
    private _createdDate: string;
    private _partnerCallbackUrl: string;
    private _redirectUrl: string;

    constructor(uuid: string, paiementDate: string, idTransactionExt: string, montantPaye: number, devise: string, telephone: string, typeTransaction: string, createdDate: string, partnerCallbackUrl: string, redirectUrl: string,
    ) {
        this._uuid = uuid;
        this._idTransactionExt = idTransactionExt;
        this._paiementDate = paiementDate;
        this._montantPaye = montantPaye;
        this._devise = devise;
        this._telephone = telephone;
        this._typeTransaction = typeTransaction;
        this._createdDate = createdDate;
        this._partnerCallbackUrl = partnerCallbackUrl;
        this._redirectUrl = redirectUrl;
    }

    static deserialize(data: any): Transaction {
        return data && new Transaction(
            data.uuid,
            data.paiementDate,
            data.idTransactionExt,
            data.montantPaye,
            data.devise,
            data.telephone,
            data.typeTransaction,
            data.createdDate,
            data.partnerCallbackUrl,
            data.redirectUrl
        );
    }

    get uuid(): string {
        return this._uuid;
    }

    set uuid(value: string) {
        this._uuid = value;
    }

    get paiementDate(): string {
        return this._paiementDate;
    }

    set paiementDate(value: string) {
        this._paiementDate = value;
    }

    get idTransactionExt(): string {
        return this._idTransactionExt;
    }

    set idTransactionExt(value: string) {
        this._idTransactionExt = value;
    }

    get montantPaye(): number {
        return this._montantPaye;
    }

    set montantPaye(value: number) {
        this._montantPaye = value;
    }

    get devise(): string {
        return this._devise;
    }

    set devise(value: string) {
        this._devise = value;
    }

    get telephone(): string {
        return this._telephone;
    }

    set telephone(value: string) {
        this._telephone = value;
    }

    get typeTransaction(): string {
        return this._typeTransaction;
    }

    set typeTransaction(value: string) {
        this._typeTransaction = value;
    }

    get createdDate(): string {
        return this._createdDate;
    }

    set createdDate(value: string) {
        this._createdDate = value;
    }

    get partnerCallbackUrl(): string {
        return this._partnerCallbackUrl;
    }

    set partnerCallbackUrl(value: string) {
        this._partnerCallbackUrl = value;
    }

    get redirectUrl(): string {
        return this._redirectUrl;
    }

    set redirectUrl(value: string) {
        this._redirectUrl = value;
    }
}
