export class Plateforme {
  private _nom: string;
  private _urlLogo: string;

  constructor(nom: string, urlLogo: string) {
    this._urlLogo = urlLogo;
    this._nom = nom;
  }

  static deserialize(data: any): Plateforme {
    return data && new Plateforme(data.nom, data.urlLogo);
  }

  get nom(): string {
    return this._nom;
  }

  set nom(value: string) {
    this._nom = value;
  }

  get urlLogo(): string {
    return this._urlLogo;
  }

  set urlLogo(value: string) {
    this._urlLogo = value;
  }

  set raison(value: string) {
    this.raison = value;
  }
}
