import React from 'react';
import { NNOTFOUNDSVG } from '../../../assets';
import './style.css';
const NotFound = () => {
    return (
            <div>
            <div className="text-not-found">Lien de paiement invalide</div>
            <div className="main"> 
            <img src={NNOTFOUNDSVG}/>
            </div>
            </div>
    );
};

export default NotFound;