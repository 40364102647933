import {useGlobalContext} from "../../../context/Global";

const useMobileMoney = () => {
    const {handleProceedPayement, loadingMobileMoney, uuid} = useGlobalContext();


    const onSubmitMobile = (data: any) => {
        console.log(data);
        if (uuid) {
            handleProceedPayement({
                uuid: uuid,
                telephone: data.phone,
                service: data.service,
                customerNumber: data.phone,
                customerOTP: data.code
            })
        }
    }
    return {
        onSubmitMobile,
        loadingMobileMoney
    }

}

export {useMobileMoney}