export class Service{
    private _wallet : boolean;
    private _mobilemoney : boolean;
    private _bankCard : boolean;
    private _services : any[];

    constructor(wallet : boolean,mobilemoney : boolean,bankCard : boolean,services : any[]) {
        this._wallet =wallet;
        this._mobilemoney=mobilemoney;
        this._bankCard=bankCard;
        this._services=services;
    }

    static deserialize(data: any): Service{
        return data && new Service(
        data.wallet,
        data.mobilemoney,
        data.bankCard,
        data.services
        );
    }

    get wallet(): boolean {
        return this._wallet;
    }

    set wallet(value: boolean) {
        this._wallet = value;
    }

    get mobilemoney(): boolean {
        return this._mobilemoney;
    }

    set mobilemoney(value: boolean) {
        this._mobilemoney = value;
    }

    get bankCard(): boolean {
        return this._bankCard;
    }

    set bankCard(value: boolean) {
        this._bankCard = value;
    }


  
    get services(): any[] {
        return this._services;
    }

    set services(value: any[]) {
        this._services = value;
    }

}
