import React from 'react';
import { AppRoutes } from './routes';

function App() {
  return (
  <AppRoutes/>)
  
}

export default App;
